<template>
  <page-setup>
    <!-- <PeopleDetailsDrawer></PeopleDetailsDrawer> -->
    <v-container fluid>
      <v-row dense>
        <v-col>
          <people-component></people-component>
        </v-col>
      </v-row>
    </v-container>
  </page-setup>
</template>

<script>
const PeopleComponent = () => import("../components/PeopleComponent.vue");
// const PeopleDetailsDrawer = () =>
//   import("../../components/app_management/drawers/PeopleDetailsDrawer.vue");

export default {
  components: {
    PeopleComponent
    // PeopleDetailsDrawer
  }
};
</script>
